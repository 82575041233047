import React, { useRef } from 'react';
import logo from './logo.png';
import './App.css';
import mithosting from './mithosting.png'
import echo from './echo.png'

function App() {
  const projectsRef = useRef(null);

  const scrollToProjects = () => {
    projectsRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="App">
      <header className="App-header bg-gray-300 dark:bg-gray-900 p-4 sm:p-8">
        <img src={logo} className="App-logo w-32 sm:w-40 h-auto mb-4 sm:mb-8 rounded-full mx-auto" alt="logo" />
        <h1 className="text-2xl sm:text-3xl font-bold mb-4 text-center">! MDK</h1>
         <button
          onClick={scrollToProjects}
          className="group inline-block rounded-full bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500 p-[2px] hover:text-white focus:outline-none focus:ring active:text-opacity-75"
        >
          <span
            className="block rounded-full bg-gray-300 dark:bg-gray-900 px-4 sm:px-8 py-2 sm:py-3 text-xs sm:text-sm font-medium group-hover"
          >
            My Projects
          </span>
        </button> 
      </header>
      <section className="bg-gray-300 dark:bg-gray-900 py-8 sm:py-12">
        <div className="container mx-auto px-4">
          <h2 className="text-2xl sm:text-3xl text-black dark:text-white font-bold mb-6 sm:mb-8 text-center">Frameworks I Can Code In</h2>
          <div className="flex flex-wrap justify-center items-center gap-8 sm:gap-16">
            {['javascript', 'html', 'css'].map((tech) => (
              <div key={tech} className="transition-transform duration-300 hover:scale-110">
                <svg
                  className={`w-16 h-16 sm:w-24 sm:h-24 ${tech === 'javascript' ? 'text-yellow-400' :
                    tech === 'html' ? 'text-orange-600' :
                      tech === 'css' ? 'text-blue-500' : ''
                    }`}
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  {tech === 'javascript' && (
                    <path d="M0 0h24v24H0V0zm22.034 18.276c-.175-1.095-.888-2.015-3.003-2.873-.736-.345-1.554-.585-1.797-1.14-.091-.33-.105-.51-.046-.705.15-.646.915-.84 1.515-.66.39.12.75.42.976.9 1.034-.676 1.034-.676 1.755-1.125-.27-.42-.404-.601-.586-.78-.63-.705-1.469-1.065-2.834-1.034l-.705.089c-.676.165-1.32.525-1.71 1.005-1.14 1.291-.811 3.541.569 4.471 1.365 1.02 3.361 1.244 3.616 2.205.24 1.17-.87 1.545-1.966 1.41-.811-.18-1.26-.586-1.755-1.336l-1.83 1.051c.21.48.45.689.81 1.109 1.74 1.756 6.09 1.666 6.871-1.004.029-.09.24-.705.074-1.65l.046.067zm-8.983-7.245h-2.248c0 1.938-.009 3.864-.009 5.805 0 1.232.063 2.363-.138 2.711-.33.689-1.18.601-1.566.48-.396-.196-.597-.466-.83-.855-.063-.105-.11-.196-.127-.196l-1.825 1.125c.305.63.75 1.172 1.324 1.517.855.51 2.004.675 3.207.405.783-.226 1.458-.691 1.811-1.411.51-.93.402-2.07.397-3.346.012-2.054 0-4.109 0-6.179l.004-.056z" />
                  )}
                  {tech === 'html' && (
                    <path d="M1.5 0h21l-1.91 21.563L11.977 24l-8.564-2.438L1.5 0zm7.031 9.75l-.232-2.718 10.059.003.23-2.622L5.412 4.41l.698 8.01h9.126l-.326 3.426-2.91.804-2.955-.81-.188-2.11H6.248l.33 4.171L12 19.351l5.379-1.443.744-8.157H8.531z" />
                  )}
                  {tech === 'css' && (
                    <path d="M1.5 0h21l-1.91 21.563L11.977 24l-8.565-2.438L1.5 0zm17.09 4.413L5.41 4.41l.213 2.622 10.125.002-.255 2.716h-6.64l.24 2.573h6.182l-.366 3.523-2.91.804-2.956-.81-.188-2.11h-2.61l.29 3.855L12 19.288l5.373-1.53L18.59 4.414z" />
                  )}
                </svg>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section ref={projectsRef} className="bg-gray-300 dark:bg-gray-900 py-8 sm:py-12">
        <div className="container mx-auto px-4">
          <h2 className="text-2xl sm:text-3xl text-black dark:text-white font-bold mb-6 sm:mb-8 text-center">My Projects</h2>
          <div className="flex justify-center">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 sm:gap-8 max-w-4xl">
              <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md overflow-hidden transition-transform duration-300 hover:scale-105 flex flex-col">
                <img src={echo} alt="Project 1" className="w-full h-48 object-cover" />
                <div className="p-4 sm:p-6 flex-grow flex flex-col">
                  <h3 className="text-lg sm:text-xl font-semibold mb-2 text-gray-800 dark:text-white">Echo Valley RP | Developer</h3>
                  <p className="text-sm sm:text-base text-gray-600 dark:text-gray-300 mb-4 flex-grow">Echo Valley RP is an immersive English FiveM server that offers a dynamic roleplaying experience set in the heart of a scenic valley. Whether you're a law-abiding citizen, a dedicated first responder, or an ambitious criminal, Echo Valley RP provides countless opportunities for unique character development and storytelling. With a rich economy, realistic law enforcement, and engaging civilian life, this server is ideal for players who want deep, meaningful interactions in a vibrant, player-driven world. Join Echo Valley RP today and shape your own destiny in this ever-evolving community!</p>
                  <div className="mt-auto">
                    <button
                      className="group inline-block rounded-full bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500 p-[2px] hover:text-white focus:outline-none focus:ring active:text-opacity-75 w-full"
                    >
                      <span
                        className="block rounded-full bg-gray-300 dark:bg-gray-800 text-black dark:text-white px-4 sm:px-8 py-2 sm:py-3 text-xs sm:text-sm font-medium group-hover w-full"
                      >
                        <a href='#'>
                          View Project
                        </a>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="bg-white dark:bg-gray-800 rounded-lg shadow-md overflow-hidden transition-transform duration-300 hover:scale-105 flex flex-col">
                <img src={mithosting} alt="Project 2" className="w-full h-48 object-cover" />
                <div className="p-4 sm:p-6 flex-grow flex flex-col">
                  <h3 className="text-lg sm:text-xl font-semibold mb-2 text-gray-800 dark:text-white">Mithosting | Supporter</h3>
                  <p className="text-sm sm:text-base text-gray-600 dark:text-gray-300 mb-4 flex-grow">Mithosting is a leading Danish game hosting provider, offering high-performance servers for gamers and communities looking for reliable, fast, and affordable hosting solutions. Specializing in a wide range of games, Mithosting ensures top-tier performance with low latency, seamless setup, and 24/7 customer support to keep your gaming experience smooth and uninterrupted. Whether you're hosting a private server for friends or building a large gaming community, Mithosting provides scalable options, easy-to-use control panels, and robust security features. Choose Mithosting for a premium gaming experience backed by a trusted Danish provider.</p>
                  <div className="mt-auto">
                    <button
                      className="group inline-block rounded-full bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500 p-[2px] hover:text-white focus:outline-none focus:ring active:text-opacity-75 w-full"
                    >
                      <span
                        className="block rounded-full bg-gray-300 dark:bg-gray-800 text-black dark:text-white px-4 sm:px-8 py-2 sm:py-3 text-xs sm:text-sm font-medium group-hover w-full"
                      >
                        <a href='https://mithosting.dk/'>
                          View Project
                        </a>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer>
        <div className="bg-gray-300 dark:bg-gray-900 py-4">
          <div className="container mx-auto text-center px-4">
            <p className="text-xs sm:text-sm text-gray-600 dark:text-gray-400">
              &copy; {new Date().getFullYear()} <a href='#' className='hover:underline'>
                !MDK
              </a> . All rights reserved.
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
}
export default App;
